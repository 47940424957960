<template>
  <v-popover
    ref="popover"
    popoverClass="popover-pipeline"
    :placement="placement"
    :disabled="!canEdit"
    offset="10px"
    :handleResize="true"
    @show="onPopoverShow"
  >
    <slot></slot>
    <template slot="popover">
      <div class="pipeline">
        <div class="label">Pipeline : {{ pipelineComputed }}</div>
      </div>
      <hr class="ligne-pipeline" />
      <div class="stages">
        <div class="label">Stade</div>
        <div class="options">
          <label
            :for="'stage-' + stage.id"
            class="stage"
            :class="{ active: stage.id == selectedStage }"
            v-for="stage in computedStages"
            :key="stage.id"
            v-tooltip.top-center="stage.name"
          >
            <input
              :id="'stage-' + stage.id"
              v-model="selectedStage"
              type="radio"
              name="cstage_id"
              :value="stage.id"
              @change="handelChange(stage.id)"
            />
          </label>
        </div>
      </div>
      <div class="substage">
        <b-form-group label="Sous-stade">
          <b-form-select
            v-model="selectedSubstage"
            :options="computedSubstages"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="message">
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
      <div class="action">
        <b-button
          variant="outline-secondary"
          size="sm"
          @click="hundlePopoverCancel()"
        >
          Annuler
        </b-button>
        <b-button variant="success" size="sm" @click="hundlePopoverOk()">
          Enregistrer
        </b-button>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions, mapGetters } from 'vuex'
Vue.component('v-popover', VPopover)
export default {
  name: 'TooltipPipeLine',
  props: {
    lead: {
      required: true
    },
    placement: {
      type: String,
      default: 'auto'
    },
    canEdit: { default: false }
  },
  data() {
    return {
      selectedStage: this.lead.cstage_id,
      selectedPipeLine: this.lead.cpipeline_id,
      selectedSubstage: this.lead.csubstage_id,
      error: null
    }
  },
  methods: {
    ...mapActions(['updateFieldStage']),
    hundlePopoverCancel() {
      this.error = null
      this.$refs['popover'].hide()
    },
    async hundlePopoverOk() {
      const payload = {
        cstage_id: this.selectedStage,
        cpipeline_id: this.selectedPipeLine,
        csubstage_id: this.selectedSubstage
      }
      if (this.selectedSubstage == undefined) {
        this.error = ['Le sous-stade est obligatoire']
        return
      }
      if (
        this.selectedStage != this.cstage_id ||
        this.selectedPipeLine != this.cpipeline_id
      ) {
        await this.updateFieldStage({
          change: payload,
          lead: this.lead
        })
        this.error = null
        this.$refs['popover'].hide()
      }
      this.$emit('changeStage')
    },
    onPopoverShow() {
      this.selectedStage = this.lead.cstage_id
      this.selectedPipeLine = this.lead.cpipeline_id
      this.selectedSubstage = this.lead.csubstage_id
    },
    handelChange(cstage_id) {
      if (cstage_id) {
        this.selectedSubstage = null
      }
    }
  },
  computed: {
    ...mapGetters(['getPlanningPipeline']),
    pipelineComputed: function() {
      let pipeline = null
      this.getPlanningPipeline.forEach(p => {
        if (p.id == this.selectedPipeLine) {
          pipeline = p.name
        }
      })
      return pipeline
    },
    computedStages: function() {
      let stages = []
      this.getPlanningPipeline.forEach(p => {
        if (p.id == this.selectedPipeLine && p.stages) {
          stages = p.stages
        }
      })
      return stages
    },
    computedSubstages: function() {
      let substages = []
      this.getPlanningPipeline.forEach(p => {
        if (p.id == this.selectedPipeLine && p.stages) {
          p.stages.filter(s => {
            if (s.id == this.selectedStage) {
              substages = s.csubstages.data
            }
          })
        }
      })
      return substages
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-pipeline {
    opacity: 1;
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 5px 0 0;
      text-align: left;
      max-width: unset;
      width: 300px;

      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }

      .substage {
        .form-group {
          margin: 0 16px 10px;
        }
      }
      .pipeline {
        text-align: center;
      }
      .ligne-pipeline {
        margin-top: 0.2rem;
      }
      .stages {
        margin: -11px 16px 6px;
        .label {
          padding-bottom: calc(0.375rem + 1px);
          margin-bottom: 0;
          font-size: inherit;
          line-height: 1.5;
          color: #656565;
        }

        .options {
          display: table;
          width: 100%;
          overflow: hidden;
          margin-bottom: 4px;
          border-radius: 4px;
          table-layout: fixed;
          .stage {
            height: 28px;
            cursor: pointer;
            display: table-cell;
            text-align: center;
            padding: 0;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            background: #08a742;
            position: relative;

            &:before {
              width: 20px;
              height: 24px;
              position: absolute;
              right: -11px;
              top: 2px;
              border-top: 3px solid #fff;
              border-right: 3px solid #fff;
              transform: scaleX(0.3) rotate(45deg);
              content: ' ';
              background: #08a742;
              cursor: pointer;
              z-index: 1;
            }

            input {
              width: auto;
              position: absolute;
              opacity: 0;
              cursor: pointer;
              border: none;
              padding: 0;
              box-shadow: none;
              margin-right: 3px;
            }

            &.active {
              background: #08a742;
            }
            &.active ~ label {
              background: #e5e5e5;
            }

            &.active ~ label:before {
              background: #e5e5e5;
            }
          }
        }
      }
      .action {
        margin: 0;
        padding: 8px 16px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #f7f7f7;
        button {
          &:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 5px;

      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
</style>
